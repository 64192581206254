




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { FscModal },
})
export default class AnswerMultipleWithMedia extends Vue {
  public name = "AnswerMultipleWithMedia";

  @Prop()
  public answer!: any;

  @Prop()
  public multipleQuestion!: any;

  @Prop()
  public context!: any;

  private questionOptions: Array<any> = [];

  public clickedOnOption: any = -1;

  public get answerMediaId(): string {
    return "answer-media-id-" + this.context;
  }

  public get previewOnlyQuestion(): boolean {
    return !this.answer;
  }

  // TODO THE SAME Function IS IN AnswerMultiple component, we need somehow to make it a common use
  @Watch("multipleQuestion", { immediate: true, deep: true })
  public onMultipleQuestionChange(question: any): void {
    if (question && question.correctAnswer) {
      const questionCheckBoxes = question.correctAnswer.split(";");
      const answerCheckBoxes = !this.previewOnlyQuestion && this.answer[0].answer ? this.answer[0].answer.split(";") : [];
      this.questionOptions = question.options.map((opt: any, index: number) => {
        if (questionCheckBoxes.some((que: any) => Number(que) === opt.position)) {
          if (answerCheckBoxes.some((ans: any) => Number(ans) === opt.position)) {
            return { index: index, correct: true, checked: true, text: opt.text, questionChecked: true };
          }
          return { index: index, correct: false, checked: false, text: opt.text, questionChecked: true };
        }
        if (questionCheckBoxes.some((que: any) => Number(que) !== opt.position)) {
          if (answerCheckBoxes.some((ans: any) => Number(ans) === opt.position)) {
            return { index: index, correct: false, checked: true, text: opt.text, questionChecked: false };
          }
          return { index: index, correct: true, checked: false, text: opt.text, questionChecked: false };
        }
      });
    }
  }

  private showInModal(optionPosition: any): void {
    this.clickedOnOption = optionPosition;
    this.$bvModal.show(this.answerMediaId);
  }

  public getMediaUrl(optionPosition: number): string {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.multipleQuestion.id}/${optionPosition}/media/option?reducedSize=false`;
  }

  public setDefaultImage(event: any) {
    event.target.src = require("@/assets/lms-drivebuzz.png");
  }
}
